import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    // 重定向
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: "/index",
        name: 'index',
        meta: {
            title: '首页'
        },
        component: () =>
            import ('@/views/index.vue'),
    },
    {
        path: '/goodS',
        name: 'goodS',
        meta: {
            title: 'S级小型种植机'
        },
        component: () =>
            import ('@/views/goodS.vue'),
    }, {
        path: '/typeSDetail',
        name: 'typeSDetail',
        meta: {
            title: 'S级小型种植机详情'
        },
        component: () =>
            import ('@/views/typeSDetail.vue')
    }, {
        path: '/aboutBrand',
        name: 'aboutBrand',
        meta: {
            title: '关于品牌'
        },
        component: () =>
            import ('@/views/aboutBrand.vue')
    },
    {
        path: '/cultivation',
        name: 'cultivation',
        meta: {
            title: '方寸间智能栽培'
        },
        component: () =>
            import ('@/views/cultivation.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router