import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'


import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video


import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)

// import 'swiper/swiper-bundle.css'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )


Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')