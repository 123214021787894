<template>
	<!-- <transition name="slide-fade"> -->
	<div>
		<div v-if="flag" class="container">
		</div>
		<div class="content" :class="flag?'active':''">
			<div class="img-box">
				<img src="@/assets/image/icon-close.png" class="img-close" @click="handleClose" />
			</div>
			<div class="item-box">
				<ul>
					<li class="item" @click="handleAbout">关于品牌</li>
					<li class="item">大客户计划</li>
					<li class="item">代理加盟</li>
					<li class="item">联系我们</li>
					<li class="item">常见问题</li>
				</ul>
			</div>
		</div>
	</div>
	<!-- </transition> -->
</template>

<script>
	export default {
		name: 'aslide',
		data() {
			return {
				flag: false
			}
		},
		mounted() {
			this.$bus.$on('menuClick', this.handleShow)
		},
		methods: {
			handleShow() {
				this.flag = true
			},
			handleClose() {
				this.flag = false
			},
			handleAbout() {
				this.$router.push('/aboutBrand');
				this.$bus.$emit('navClick', true);
				this.flag = false;
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		justify-content: flex-end;
	}

	.content {
		height: 100%;
		transition: all ease-in 0.3s;
		background-color: #fff;
		position: fixed;
		width: 0;
		top: 0;
		right: -300px;
		z-index: 10000;
	}

	.active {
		width: 200px;
		right: 0;
	}

	.img-box {
		padding: 15px 50px;
	}

	.img-close {
		width: 40px;
		height: 40px;
	}

	.item-box {
		width: 300px;
		margin-top: 80px;
	}

	.item {
		padding: 24px 50px;
		font-size: 16px;
		color: #111111;

		&:hover {
			background-color: rgba(74, 177, 126, .1);
		}
	}

	@media screen and (max-width:1500px) {
		.img-close {
			width: 30px;
			height: 30px;
		}
		
		.item {
			font-size: 14px;
		}
	}
</style>
