<template>
	<div id="app" ref="main">
		<aslide></aslide>
		<router-view />
	</div>
</template>

<script>
	import aslide from '@/components/aslide.vue'

	export default {
		name: 'app',
		components: {
			aslide
		},
		created() {
			document.title = "创农"
		},
		watch: {
			$route() {
				let main = this.$refs.main
				main.scrollTo(0, 0)
			}
		}
	}
</script>

<style>
	@import url("assets/css/reset.css");
	@import url("assets/css/iconfont.css");

	body,
	html,
	#app {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
</style>
